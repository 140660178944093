.group-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.group-content {
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.diagram {
  max-width: 30vw;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
}
