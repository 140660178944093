.wrapper {
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 3vw;
}

.text {
    padding-right: 3vw;
    text-align: justify;
}
