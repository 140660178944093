.Sidebar {
  height: 90vh;
  width: 12vw;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Nav {
  padding: 0;
  margin: 0;
}

.Nav-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  color: black;
  padding-left: 0.5vw;

  &:hover {
    background-color: rgb(0, 136, 73, 0.8);
  }
}

.Nav-link-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Nav-link-list-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-decoration: none;
  color: black;
  padding-left: 2vw;
  padding-top: 0.3vw;
  padding-bottom: 0.3vw;

  &:hover {
    background-color: rgb(0, 136, 73, 0.5);
  }
}
