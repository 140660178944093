.App {
  text-align: center;
}

.Logo {
  max-height: 6vh;
  pointer-events: none;
}

.Header {
  background-color: rgb(0, 136, 73);
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.Content-row {
  display: flex;
  flex-direction: row;
}

.Content {
  width: 88vw;
  height: 90vh;
}

.ordered-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

code {
  background-color: lightgrey;
}
